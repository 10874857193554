import React, { useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import styles from "./flight-status-mobile.module.scss";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import FlightStatus from "../index";
import { useTranslation } from "react-i18next";

interface IProps {
  showSidebar: boolean;
  setShowSidebar: any;
  activeTab?: string;
}

const MobileFlightStatus: React.FC<IProps> = (props: IProps) => {
  const isDesktopView = useScreenSize()?.deviceSize?.width > 767;
  const { t } = useTranslation();
  useEffect(() => {
    document.documentElement.style.setProperty("--body-overflow", "hidden");
    return () => {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };
  }, []);

  return (
    <>
      {!isDesktopView && (
        <Offcanvas
          show={props?.showSidebar}
          placement="start"
          onHide={() => props?.setShowSidebar(false)}
          className={styles["flight-status-offcanvas"]}
          backdropClassName={styles["flight-status-offcanvas-back"]}
        >
          <Offcanvas.Header
            closeButton
            className={styles["flight-status-offcanvas-header"]}
          >
            <Offcanvas.Title className={styles["flight-status-label"]}>
              {t("label_book_widget_flight_status")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className={styles["flight-status-offcanvas-body"]}>
            <FlightStatus
              handleCloseSidebar={props?.setShowSidebar}
              activeTab={props?.activeTab}
              isHidden={true}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default MobileFlightStatus;
