// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xlU5ClowdePA0Zbi1OpE {
  width: 100%;
  top: 54px !important;
}

.usvHMHSTxOJz8MWvWjAC {
  width: 100% !important;
  top: 47px !important;
  border: 0 !important;
}

.JCK1sIW79iDpm_20s1Zl {
  padding-top: 31px;
}

.MsT4MAyPJf4Bw6t4AQ7S {
  color: var(--gray-text-strong, #2C3059);
  font-family: Bellefair;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  text-transform: uppercase;
}

.W17US6BfP6VZiyJvXtJ2 .flight-status-route-button {
  position: absolute;
  width: 95%;
  bottom: 40px;
}
.W17US6BfP6VZiyJvXtJ2 .flight-status-number-button {
  position: absolute;
  width: 95%;
  bottom: 40px;
}
.W17US6BfP6VZiyJvXtJ2 .floating-select-bottomsheet {
  z-index: 1045 !important;
}
.W17US6BfP6VZiyJvXtJ2 .floating-select-bottomsheet-backdrop {
  z-index: 1044 !important;
}`, "",{"version":3,"sources":["webpack://./src/OSCI/src/component/booking-widget/flight-status/flight-status-mobile/flight-status-mobile.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,oBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,oBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,uCAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,yBAAA;AACJ;;AAIQ;EACI,kBAAA;EACA,UAAA;EACA,YAAA;AADZ;AAGQ;EACI,kBAAA;EACA,UAAA;EACA,YAAA;AADZ;AAGQ;EACI,wBAAA;AADZ;AAGQ;EACI,wBAAA;AADZ","sourcesContent":[".flight-status-offcanvas-back {\n    width: 100%;\n    top: 54px !important;\n}\n\n.flight-status-offcanvas {\n    width: 100% !important;\n    top: 47px !important;\n    border: 0 !important;\n}\n\n.flight-status-offcanvas-header {\n    padding-top: 31px;\n}\n\n.flight-status-label {\n    color: var(--gray-text-strong, #2C3059);\n    font-family: Bellefair;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 150%; /* 36px */\n    text-transform: uppercase;\n}\n\n.flight-status-offcanvas-body {\n    & :global {\n        .flight-status-route-button {\n            position: absolute;\n            width: 95%;\n            bottom: 40px;\n        }\n        .flight-status-number-button {\n            position: absolute;\n            width: 95%;\n            bottom: 40px;\n        }\n        .floating-select-bottomsheet {\n            z-index: 1045 !important;\n        }\n        .floating-select-bottomsheet-backdrop {\n            z-index: 1044 !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flight-status-offcanvas-back": `xlU5ClowdePA0Zbi1OpE`,
	"flight-status-offcanvas": `usvHMHSTxOJz8MWvWjAC`,
	"flight-status-offcanvas-header": `JCK1sIW79iDpm_20s1Zl`,
	"flight-status-label": `MsT4MAyPJf4Bw6t4AQ7S`,
	"flight-status-offcanvas-body": `W17US6BfP6VZiyJvXtJ2`
};
export default ___CSS_LOADER_EXPORT___;
