import React, { useEffect, useState } from "react";
import styles from "./flight-status-detail.module.scss";
import FlightStatus from "..";
import TGIcon from "../../../../../../shared/tg-icon";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { RootState } from "../../../../slice/RootReducer";
import { useSelector } from "react-redux";
import {
  displayDateFormat,
  getLocalStorageData,
  setLocalStorageData,
  updateRecentSearchFlightStatus,
} from "../../../../../../utils/helper";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { AirportData } from "../../../../models/models";
import { Card, Placeholder } from "react-bootstrap";
import MobileFlightStatus from "../flight-status-mobile";

interface Props {
  type?: any;
}

const FlightStatusDetail: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const flightNumberDetailSelector = useSelector(
    (state: RootState) => state?.osciReducer?.flightStatus.flightNumberDetail
  );

  const airportData: AirportData | any = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.airportData
  );

  const [flightStatusData, setFlightStatusData] = useState<any>([]);
  const [flightRecentSearchData, setFlightRecentSearchData] = useState<any[]>(
    []
  );
  const isFlightStatusError = useSelector(
    (state: RootState) => state?.osciReducer?.flightStatus.isError
  );

  const flightNumberSaveData = useSelector(
    (state: RootState) => state?.osciReducer?.flightStatus.flightNumberInputs
  );

  const isFlightNumberLoading = useSelector(
    (state: RootState) => state?.osciReducer?.flightStatus.isLoading
  );

  const [showSidebar, setShowSidebar] = useState(false);

  const [displayFlightStatus, setDisplayFlightStatus] = useState(true);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (flightNumberDetailSelector) {
      const result = flightNumberDetailSelector?.flightList;
      let flightSearchData = {
        from: renderAirportDetail(result.departure),
        to: renderAirportDetail(result.arrival),
        date: flightNumberSaveData?.date,
        flightNumber: flightNumberSaveData?.flightNumber,
        type: "Flight Number",
        url:
          process.env.REACT_APP_TG_IMAGE_SEARCH_URL + result.arrival + ".jpg",
      };
      updateRecentSearchFlightStatus(
        setFlightRecentSearchData,
        "recentSearchFlight",
        null,
        flightSearchData,
        10
      );

      setFlightStatusData([result]);
    } else {
      setFlightStatusData([]);
    }
  }, [flightNumberDetailSelector]);

  const screenSize = useScreenSize().deviceSize.width;

  const handleDisplayFlightStatus = () => {
    setDisplayFlightStatus((prevState) => !prevState);
  };

  const renderAirportDetail = (code: string) => {
    const filteredObj = airportData?.filter(
      (x: any) => x.airportCode === code
    )?.[0];
    return filteredObj;
  };

  const renderStatus = (cS: string) => {
    switch (cS) {
      case "Arrived":
      case "On Time":
      case "Scheduled":
        document.documentElement.style.setProperty(
          "--status-text-color",
          "#006723"
        );
        document.documentElement.style.setProperty(
          "--status-bg-color",
          "#D0F8DE"
        );
        return cS;
      case "Delayed":
        document.documentElement.style.setProperty(
          "--status-text-color",
          "#CC9405"
        );
        document.documentElement.style.setProperty(
          "--status-bg-color",
          "#FFF5DC"
        );
        return cS;
      case "Cancelled":
        document.documentElement.style.setProperty(
          "--status-text-color",
          "#FF2E57"
        );
        document.documentElement.style.setProperty(
          "--status-bg-color",
          "#FFE5EA"
        );
        return cS;
    }
  };

  const handleSidebar = () => {
    window.scrollTo(0, 0);
    setShowSidebar(true);
  };

  const renderExpandableLink = () => {
    return (
      <div className={styles["flight-status-flight-route-sidebar-status"]}>
        <div onClick={handleDisplayFlightStatus}>
          {displayFlightStatus ? (
            <>
              {t("label_hideflightstatus")}{" "}
              <span>
                <TGIcon
                  icon="summary-redemption-up-arrow-icon"
                  size="20px"
                  fillColor=""
                />
              </span>
            </>
          ) : (
            <>
              {t("label_showflightstatus")}{" "}
              <span>
                <TGIcon
                  icon="summary-redemption-down-arrow-icon"
                  size="20px"
                  fillColor=""
                />
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderStatusSection = (obj: any, otherObj: any, isArrival: boolean) => {
    return (
      <>
        <div
          className={`${styles["flight-status-more-details-stepper"]} d-flex flex-column align-items-center`}
        >
          {obj.code ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <circle
                cx="10"
                cy="8"
                r="6"
                stroke={isArrival ? "#DFE0EB" : "#6A6D87"}
                strokeWidth="1.6"
              />
            </svg>
          ) : (
            <svg width="18" height="0"></svg>
          )}
          <div
            className={`${
              isArrival
                ? styles["flight-status-stepper-line-arrival"]
                : styles["flight-status-stepper-line-departure"]
            } line h-100`}
          ></div>
        </div>
        <div
          className={`${styles["flight-status-content-wrapper"]} d-flex w-100`}
        >
          <div
            className={`${styles["flight-status-content"]} d-flex flex-column`}
          >
            <div
              className={
                isArrival
                  ? styles["flight-status-more-details-message-arrival"]
                  : styles["flight-status-more-details-message"]
              }
            >
              {obj.message}
            </div>
            <div className={styles["flight-status-more-details-time"]}>
              {obj?.time &&
                `${obj.time?.substring(0, 2)}:${obj.time?.substring(4, 2)}`}
            </div>
          </div>
          <div
            className={` ${styles["flight-status-airport-content"]} d-flex flex-column`}
          >
            <div
              className={
                isArrival
                  ? styles["flight-status-more-details-airport-data-arrival"]
                  : styles["flight-status-more-details-airport-data"]
              }
            >
              {renderAirportDetail(
                isArrival ? otherObj.arrival : otherObj.departure
              )?.cityName?.replace(" ", "") +
                ", " +
                renderAirportDetail(
                  isArrival ? otherObj.arrival : otherObj.departure
                )?.countryName}
            </div>
            <div className={styles["flight-status-more-details-time"]}>
              {
                renderAirportDetail(
                  isArrival ? otherObj.arrival : otherObj.departure
                )?.airportName
              }
            </div>
            <div className={`${styles["flight-status-more-details-time"]}`}>
              {(isArrival
                ? otherObj.arrivalTerminal
                : otherObj.departureTerminal) &&
                `Terminal ${
                  isArrival
                    ? otherObj.arrivalTerminal
                    : otherObj.departureTerminal
                }`}
            </div>
            <div></div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className={`${styles["flight-status-container"]} global-content-padding`}
    >
      <div className={styles["flight-status-booking-container"]}>
        {screenSize > 767 ? (
          <FlightStatus activeTab={"Flight Number"} isHidden={true} />
        ) : (
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <div className={styles["mobile-search-header"]}>
                TG {flightNumberSaveData?.flightNumber}
              </div>
              <div className={styles["mobile-search-header-date"]}>
                {flightNumberSaveData?.date}
              </div>
            </div>
            <div
              className={styles["mobile-search-header-button"]}
              onClick={handleSidebar}
            >
              {t("label_redemption_edit_search")}
            </div>
          </div>
        )}
      </div>
      {flightStatusData?.length > 0 && !isFlightStatusError && (
        <div className={styles["flight-status-inner-wrapper"]}>
          <h4 className={styles["city-detail-header"]}>{t("label_route")}</h4>
          <h2 className={styles["city-detail"]}>
            {renderAirportDetail(flightStatusData[0]?.departure)?.cityName}-
            {renderAirportDetail(flightStatusData[0]?.arrival)?.cityName}
          </h2>
        </div>
      )}
      <div className="d-flex flex-column mb-4">
        {flightStatusData?.length > 0 && !isFlightStatusError ? (
          <>
            <div className={styles["flight-status-flight-route-container"]}>
              <div
                className={`${styles["flight-status-flight-route-card"]} ${
                  !displayFlightStatus &&
                  styles["flight-status-flight-route-card-bottom-border"]
                }`}
              >
                <div className={styles["flight-status-flight-route-sidebar"]}>
                  <div
                    className={
                      styles["flight-status-flight-route-sidebar-logo"]
                    }
                  >
                    <span>
                      <TGIcon
                        icon="thai-header-logo"
                        fillColor=""
                        size="20px"
                      />
                    </span>
                    <TGIcon
                      icon="thai-header-text"
                      fillColor=""
                      size="20"
                      viewBox="0 0 82 18"
                    />
                  </div>
                  <div
                    className={
                      styles["flight-status-flight-route-sidebar-flightnum"]
                    }
                  >
                    {t("label_book_widget_tg")} {flightStatusData[0]?.flightNum}
                  </div>
                  <div
                    className={
                      styles["flight-status-flight-route-sidebar-date"]
                    }
                  >
                    <div>
                      {displayDateFormat(
                        moment(flightStatusData[0]?.departureDate, "DDMMYY")
                      )}
                    </div>
                    <div>
                      {flightStatusData[0]?.duration
                        ? `Trip Duration: ${flightStatusData[0]?.duration?.substring(
                            0,
                            2
                          )} h ${flightStatusData[0]?.duration?.substring(
                            2,
                            4
                          )} min`
                        : ""}
                    </div>
                    <div>{flightStatusData[0]?.aircraftType}</div>
                  </div>
                  {screenSize > 767 && renderExpandableLink()}
                </div>
                {screenSize < 768 && (
                  <div className={styles["flight-status-seperation"]}></div>
                )}
                <div
                  className={
                    styles["flight-status-flight-duration-main-section"]
                  }
                >
                  <div
                    className={
                      styles["flight-status-flight-duration-sub-section"]
                    }
                  >
                    <div
                      className={
                        styles["flight-status-flight-duration-info-section"]
                      }
                    >
                      <div className={styles["flight-status-schedule-label"]}>
                        {/* {t("label_scheduled")} NA:NA */}
                      </div>
                      <div>
                        <div className={styles["flight-status-actual-label"]}>
                          {t("label_scheduled")}
                        </div>
                        <div className={styles["flight-status-schedule-time"]}>
                          {flightStatusData[0]?.departureTime?.substring(0, 2)}:
                          {flightStatusData[0]?.departureTime?.substring(2, 4)}
                        </div>
                      </div>
                      <div>
                        <div className={styles["flight-status-airport-code"]}>
                          {flightStatusData[0]?.departure}
                        </div>
                        <div className={styles["flight-status-city-name"]}>
                          {
                            renderAirportDetail(flightStatusData[0]?.departure)
                              ?.cityName
                          }
                        </div>
                      </div>
                      <div>
                        <div className={styles["flight-status-airport-name"]}>
                          {
                            renderAirportDetail(flightStatusData[0]?.departure)
                              ?.airportName
                          }
                        </div>
                        <div
                          className={`${
                            styles["flight-status-terminal-number"]
                          } ${
                            !flightStatusData[0]?.departureTerminal &&
                            styles["flight-status-no-terminal"]
                          }`}
                        >
                          {flightStatusData[0]?.departureTerminal
                            ? `${t("label_redemption_termainal")} ${
                                flightStatusData[0]?.departureTerminal
                              }`
                            : "NA"}
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        styles["flight-status-flight-duration-section"]
                      }
                    >
                      <div>
                        {flightStatusData[0]?.duration
                          ? `${flightStatusData[0]?.duration?.substring(
                              0,
                              2
                            )} h ${flightStatusData[0]?.duration?.substring(
                              2,
                              4
                            )} min`
                          : ""}
                      </div>
                      <div>
                        <div></div>
                        <div>
                          <TGIcon icon="plane-icon" fillColor="" size="" />
                        </div>
                        <div></div>
                      </div>
                      {flightStatusData[0]?.numOfStops === 0 && (
                        <div>{t("label_nonstop")}</div>
                      )}
                      {flightStatusData[0]?.numOfStops !== 0 && (
                        <div>{flightStatusData[0]?.numOfStops}</div>
                      )}
                    </div>
                    <div
                      className={
                        styles["flight-status-flight-duration-info-section"]
                      }
                    >
                      <div
                        className={styles["flight-status-schedule-label"]}
                      ></div>
                      <div>
                        <div className={styles["flight-status-actual-label"]}>
                          {t("label_scheduled")}
                        </div>
                        <div className={styles["flight-status-schedule-time"]}>
                          {flightStatusData[0]?.arrivalTime?.substring(0, 2)}:
                          {flightStatusData[0]?.arrivalTime?.substring(2, 4)}
                        </div>
                      </div>
                      <div>
                        <div className={styles["flight-status-airport-code"]}>
                          {flightStatusData[0]?.arrival}
                        </div>
                        <div className={styles["flight-status-city-name"]}>
                          {
                            renderAirportDetail(flightStatusData[0]?.arrival)
                              ?.cityName
                          }
                        </div>
                      </div>
                      <div>
                        <div className={styles["flight-status-airport-name"]}>
                          {
                            renderAirportDetail(flightStatusData[0]?.arrival)
                              ?.airportName
                          }
                        </div>
                        <div
                          className={`${
                            styles["flight-status-terminal-number"]
                          } ${
                            !flightStatusData[0]?.arrivalTerminal &&
                            styles["flight-status-no-terminal"]
                          }`}
                        >
                          {flightStatusData[0]?.arrivalTerminal
                            ? `${t("label_redemption_termainal")} ${
                                flightStatusData[0]?.arrivalTerminal
                              }`
                            : "NA"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {screenSize < 768 && renderExpandableLink()}
              </div>
            </div>
            {displayFlightStatus && (
              <div className={styles["flight-status-flight-number-details"]}>
                <div
                  className={styles["flight-status-flight-route-more-details"]}
                >
                  {flightStatusData[0]?.departureStatus ? (
                    <>
                      {!(
                        flightStatusData[0]?.departureStatus?.length === 0 &&
                        flightStatusData[0]?.arrivalStatus?.length === 0
                      ) && (
                        <div className="d-flex gap-3 mb-4">
                          <div
                            className={
                              styles["flight-status-more-details-header"]
                            }
                          >
                            {t("label_currentflightstatus")}
                          </div>
                          <div
                            className={
                              styles["flight-status-more-details-status"]
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                            >
                              <circle cx="4" cy="4" r="4" fill="#CC9405" />
                            </svg>
                            {renderStatus(flightStatusData[0]?.status)}
                          </div>
                        </div>
                      )}
                      {flightStatusData[0]?.departureStatus?.length === 0 &&
                        flightStatusData[0]?.arrivalStatus?.length === 0 && (
                          <div
                            className={styles["no-result-icon-status-wrapper"]}
                          >
                            <div className={styles["no-result-icon"]}>
                              <TGIcon
                                icon="no-flight-status"
                                fillColor=""
                                size=""
                              />
                            </div>
                            <div
                              className={styles["flight-status-no-result-msg"]}
                            >
                              {t("label_flight_status_not_available")}
                            </div>
                            <div
                              className={styles["flight-status-no-result-desc"]}
                            >
                              {t("label_flight_status_update")}
                            </div>
                          </div>
                        )}
                      {flightStatusData[0]?.departureStatus?.map(
                        (z: any, n: boolean) => (
                          <div
                            className={`${styles["flight-status-more-details-wrapper"]} d-flex w-100`}
                            key={`departure${n}`}
                          >
                            {renderStatusSection(z, flightStatusData[0], false)}
                          </div>
                        )
                      )}
                      {flightStatusData[0]?.arrivalStatus?.map(
                        (z: any, cI: number) => (
                          <div
                            className={`${styles["flight-status-more-details-wrapper"]} d-flex w-100`}
                            key={`arrival${cI}`}
                          >
                            {renderStatusSection(z, flightStatusData[0], true)}
                          </div>
                        )
                      )}
                    </>
                  ) : isFlightStatusError ? (
                    <div className={styles["flight-status-no-result"]}>
                      {t("label_flight_status_error")}
                    </div>
                  ) : (
                    [1, 2]?.map((x: any) => (
                      <div className={styles["skelton-loader"]}>
                        <div className="mb-3">
                          <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={6} />
                          </Placeholder>
                          <Placeholder as={Card.Text} animation="glow">
                            <Placeholder xs={4} />{" "}
                          </Placeholder>
                        </div>
                        <div>
                          <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={6} />
                          </Placeholder>
                          <Placeholder as={Card.Text} animation="glow">
                            <Placeholder xs={7} /> <Placeholder xs={4} />
                          </Placeholder>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          !isFlightNumberLoading && (
            <div className={styles["no-result-icon-wrapper"]}>
              <div className={styles["no-result-icon"]}>
                <TGIcon icon="no-flight-found" fillColor="" size="" />
              </div>
              <div className={styles["flight-status-no-result-msg"]}>
                {t("label_flight_status_error")}
              </div>
              <div className={styles["flight-status-no-result-desc"]}>
                {t("label_flight_status_search_criteria")}
              </div>
            </div>
          )
        )}
      </div>
      {showSidebar && (
        <MobileFlightStatus
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          activeTab={"Flight Number"}
        />
      )}
    </div>
  );
};

export default FlightStatusDetail;
